.InboxPage{
 background-color: #111B21;  
}

.InboxPage-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    background-color: #1F2C33;
}

.InboxPage-headerName{
    font-size: 2.2rem;
    font-weight: 600;
    color: lightgray;
}

.InboxPage-headerMid{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-left: 2rem;
}

.InboxPage-headerAmount{
    font-size: 1.3rem;
    padding-top: 0.5rem;
}

.InboxPage-headerLeft{
    display: flex;
    align-items: center;
}


.InboxPage-body{
 height: auto; 
 min-height: 100vh;
}

.InboxPage-footer{
    position: fixed;
    width: 100%;
    background-color: #1F2C33;
    height: auto;
    z-index: 100;
    bottom: 0;

}


.InboxPage-footer > *{
    padding: 2.2rem !important;
    width: 90% !important;
    height:4rem !important;
    font-size: 1.5rem !important;
    text-transform: unset !important;
    border-radius: 10rem !important;
    color: lightgray !important;
    background-color: #118C7E !important;
    margin: 1.5rem !important;
    z-index: 100;
}