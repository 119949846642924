.MessageCard{
    color: white;
    background-color: #1F2C33;
    border-radius: 10px;
    width: 50%;
    height: auto;
    padding: 1rem;
    margin: 1rem;
    display: inline-block;
}

.MessageCard-sender > p{
    text-align: left;
    font-size: 1.2rem;
}

.MessageCard-amount > p{
    font-size: 3rem;
    padding: 1rem;
}


.MessageCard-details{
    display: flex;
    /* flex-direction: column; */
    justify-content: space-between;
    align-items: flex-end ;
    padding: 0 0.5rem;
}


.MessageCard-left{
    text-align: left;
    max-width: 70%;
}

.MessageCard-status{
    font-weight: 600;
    font-size: 1.3rem;
    padding-bottom: 0.5rem;
}

.MessageCard-right { 
    text-align: right;
}