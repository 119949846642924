.AddSplit{
    width: 100vw;
    height: 100vh;
    background-color: #1F2C33;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10rem;
}

.AddSplit > * { 
    margin: 2rem;
}

.AddSplit-details{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.AddSplit-text{
    font-size: 2rem;
    color: white;
}

.AddSplit-total{
    margin: 2rem;
    padding: 2.2rem;
    border: none;
    cursor: pointer;
    outline: none;
    text-align: center;
    height:4rem;
    width: 100%;
    font-size:5rem;
    font-weight: 500;
    border-radius: 10rem;
    color: white;
    background-color: #1F2C33;

}

.AddSplit-for{
    padding: 2.2rem;
    border: none;
    cursor: pointer;
    outline: none;
    width: 20rem;
    text-align: center;
    height:4rem;
    font-size: 1.5rem;
    font-weight: 500;
    border-radius: 10rem;
    color: white;
    background-color: #118C7E;
}


input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
} 

::placeholder { 
    color: lightgray;
}


.AddSplit-SplitBetweenProfiles{
    width: 100vw !important;
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
    color: whitesmoke !important;
    padding: 0 3rem !important;
    
}
.AddSplit-SplitBetweenProfiles-left{
    display: flex;
    align-items: center;
}

.AddSplit-SplitBetweenProfiles-left > *{
    padding: 0.7rem;
    font-size: 1.8rem;
}

.AddSplit-SplitBetweenProfiles-right{
    float: right;
}



.AddSplit-button > *{
    padding: 2.2rem !important;
    width: 20rem !important;
    height:4rem !important;
    font-size: 1.5rem !important;
    text-transform: unset !important;
    font-weight: 500 !important;
    border-radius: 10rem !important;
    color: lightgray !important;
    background-color: #118C7E !important;
   
}





