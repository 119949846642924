.ChatCard{
    padding: 1.5rem;
    display: flex;
    align-items: center;
    background-color: #111B21;
    /* justify-content: space-between; */
}

.ChatCard-avatar > img {
    border-radius: 50%;
}

.ChatCard-details{
    margin-left: 2rem;
}

.ChatCard-name{
    font-size: 2rem;
    font-weight: 600;
    color: #c1c1c1;
    text-align: left;
}

.ChatCard-info{
    display: flex;
    align-items: center;    
}

.ChatCard-info > *{
    padding: 0.3rem 1rem;
    font-size: 1.3rem;
    font-weight: 500;
}