.LoginPage{
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.LoginPage-heading{
    font-size: 2.5rem;
    width: 80%;
    max-width: 40rem;
}

.LoginPage-image{
    margin: 4rem 0 4rem 0;
    width: 80%;
    max-width: 40rem;
    aspect-ratio: 1/1;
    border-radius: 50%;
    object-fit: contain;
    background-color: white;
}


.LoginPage-rightButton{
    width: auto;
    height: 5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 70%;
    max-width: 40rem;

}

.LoginPage-rightButton > p{
    margin-left: 2rem;
}